import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { registerUser } from "../actions/authActions";
import "./Auth.scss";

class Register extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    error: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.auth.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidUpdate() {
    if (this.props.auth.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ error: null, loading: true });
    let { name, email, password } = this.state;

    let user = {
      name: name.trim(),
      email: email.trim().toLowerCase(),
      password,
    };

    let delay = 500;

    setTimeout(() => {
      this.props.registerUser(
        user,
        this.props.history,
        this.handleError,
        this.handleLoading
      );
    }, delay);
  };

  handleLoading = () => {
    this.setState({ loading: false });
  };

  handleError = (error) => {
    this.setState({ error: error });
  };

  render() {
    let { error, loading } = this.state;

    return (
      <div className="auth-form">
        <div className="form-header">Register</div>
        <div className="form-description">
          <Link className="link" to="/login">
            or Login
          </Link>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="input-group">
            <div className="input-label">Name</div>
            <input
              className="input"
              onChange={this.handleChange}
              value={this.state.name}
              required
              id="name"
              name="name"
              type="text"
              placeholder="Enter your name"
            />
          </div>
          <div className="input-group">
            <div className="input-label">Email</div>
            <input
              className="input"
              onChange={this.handleChange}
              value={this.state.email}
              required
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="input-group">
            <div className="input-label">Password</div>
            <input
              className="input"
              onChange={this.handleChange}
              value={this.state.password}
              required
              id="password"
              name="password"
              type="password"
              placeholder="Enter your password"
            />
          </div>
          {error && <div className="input-error">{error}</div>}
          <button className="input-button">
            {loading ? (
              <div className="input-button-loader"></div>
            ) : (
              <span>Register</span>
            )}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
