// Auth actions
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SET_USER_LOADING = "SET_USER_LOADING";

// Project actions
export const CREATE_PROJECT = "CREATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const SET_PROJECTS_LOADING = "SET_PROJECTS_LOADING";
export const GET_FEEDBACK = "GET_FEEDBACK";
export const DELETE_FEEDBACK = "DELETE_FEEDBACK";
export const CLEAR_FEEDBACK = "CLEAR_FEEDBACK";
export const FILTER_FEEDBACK = "FILTER_FEEDBACK";
export const SET_FEEDBACK_LOADING = "SET_FEEDBACK_LOADING";
