import {
  SET_CURRENT_USER,
  SIGNOUT_USER,
  SET_USER_LOADING,
} from "../actions/types";

const initialState = {
  isLoggedIn: false,
  user: {},
  userLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          name: action.payload.name,
        },
      };
    case SIGNOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
        user: {},
      };
    case SET_USER_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    default:
      return state;
  }
}
