import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { AXIOS_BASE_URL } from "./axiosBaseUrl";
import { SET_CURRENT_USER, SET_USER_LOADING, SIGNOUT_USER } from "./types";

// Register user
export const registerUser = (user, history, handleError, handleLoading) => (
  dispatch
) => {
  axios
    .post(`${AXIOS_BASE_URL}/auth/register`, user)
    .then(() => {
      dispatch(loginUser(user, history, handleError, handleLoading));
    })
    .catch((err) => {
      let { error } = err.response.data;
      handleError(error);
      handleLoading();
    });
};

// Login user and set token
export const loginUser = (user, history, handleError, handleLoading) => (
  dispatch
) => {
  axios
    .post(`${AXIOS_BASE_URL}/auth/login`, user)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("feederJwtToken1337", token);

      // Set token to Auth header
      setAuthToken(token);

      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user
      dispatch(setCurrentUser(decoded));

      // Push to dashboard
      history.push("/dashboard");
    })
    .catch((err) => {
      let { error } = err.response.data;
      handleError(error);
      handleLoading();
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = (history) => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("feederJwtToken1337");

  // Remove auth header for future requests
  setAuthToken(false);

  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch({
    type: SIGNOUT_USER,
    payload: null,
  });

  // Redirect to login
  history.push("/");
};

// User loading
export const setUserLoading = () => {
  return {
    type: SET_USER_LOADING,
  };
};
