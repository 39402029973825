import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { loginUser } from "../actions/authActions";
import "./Auth.scss";

class Login extends Component {
  state = {
    email: "",
    password: "",
    error: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.auth.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidUpdate() {
    if (this.props.auth.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ error: null, loading: true });
    let { email, password } = this.state;

    let user = {
      email: email.trim().toLowerCase(),
      password,
    };

    let delay = 500; // delay in ms

    setTimeout(() => {
      this.props.loginUser(
        user,
        this.props.history,
        this.handleError,
        this.handleLoading
      );
    }, delay);
  };

  handleLoading = () => {
    this.setState({ loading: false });
  };

  handleError = (error) => {
    this.setState({ error: error });
  };

  render() {
    let { error, loading } = this.state;

    return (
      <div className="auth-form">
        <div className="form-header">Login</div>
        <div className="form-description">
          <Link className="link" to="/register">
            or Register
          </Link>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="input-group">
            <div className="input-label">Email</div>
            <input
              className="input"
              onChange={this.handleChange}
              value={this.state.email}
              required
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="input-group">
            <div className="input-label">Password</div>
            <input
              className="input"
              onChange={this.handleChange}
              value={this.state.password}
              required
              id="password"
              name="password"
              type="password"
              placeholder="Enter your password"
            />
          </div>
          {error && <div className="input-error">{error}</div>}
          <button className="input-button">
            {loading ? (
              <div className="input-button-loader"></div>
            ) : (
              <span>Login</span>
            )}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loginUser })(withRouter(Login));
