import axios from "axios";

import { AXIOS_BASE_URL } from "./axiosBaseUrl";
import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS,
  SET_PROJECTS_LOADING,
  SET_FEEDBACK_LOADING,
  GET_FEEDBACK,
  CLEAR_FEEDBACK,
  DELETE_FEEDBACK,
  UPDATE_PROJECT,
  FILTER_FEEDBACK,
} from "./types";

export const createProject = (
  project,
  handleModal,
  handleError,
  handleLoading
) => (dispatch) => {
  setTimeout(() => {
    axios
      .post(`${AXIOS_BASE_URL}/projects/create`, project)
      .then((res) => {
        let { data } = res;
        dispatch({
          type: CREATE_PROJECT,
          payload: data,
        });

        handleModal();
      })
      .catch((err) => {
        if (err.response) {
          let { error } = err.response.data;

          handleError(error);
        } else {
          console.log(err);
        }
      })
      .finally(() => handleLoading());
  }, 250);
};

export const updateProject = (
  project,
  handleModal,
  handleError,
  handleLoading
) => (dispatch) => {
  axios
    .post(`${AXIOS_BASE_URL}/projects/update`, project)
    .then((res) => {
      dispatch({
        type: UPDATE_PROJECT,
        payload: null,
      });

      handleModal();
      window.location.reload();
    })
    .catch((err) => {
      if (err.response) {
        let { error } = err.response.data;

        handleError(error);
      } else {
        console.log(err);
        handleError(err.toString());
      }
    })
    .finally(() => handleLoading());
};

export const getProjects = () => (dispatch) => {
  dispatch(setProjectsLoading);

  axios
    .get(`${AXIOS_BASE_URL}/projects/`)
    .then((res) => {
      let { data } = res;

      dispatch({
        type: GET_PROJECTS,
        payload: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getFeedback = (projectId, handleSetData, handleError) => (
  dispatch
) => {
  dispatch(setFeedbackLoading);

  axios
    .get(`${AXIOS_BASE_URL}/feedback`, { params: { projectId } })
    .then((res) => {
      let { data } = res;
      let { feedback, project } = data;

      setTimeout(() => {
        dispatch({
          type: GET_FEEDBACK,
          payload: feedback,
        });
      }, 250);

      handleSetData(feedback, project);
    })
    .catch((err) => {
      if (err.response) {
        let { error } = err.response.data;

        handleError(error);
      } else {
        console.log(err);
      }
    });
};

export const deleteProject = (projectId, history) => (dispatch) => {
  if (
    window.confirm(
      "Are you sure you want to delete this project? All associated feedback will be deleted."
    )
  ) {
    axios
      .post(`${AXIOS_BASE_URL}/projects/delete`, { projectId })
      .then(() => {
        dispatch({
          type: DELETE_PROJECT,
          payload: projectId,
        });

        history.push("/dashboard");
      })
      .catch((err) => console.log(err));
  }
};

export const deleteFeedback = (feedbackId, projectId) => (dispatch) => {
  axios
    .post(`${AXIOS_BASE_URL}/feedback/delete`, { feedbackId, projectId })
    .then(() => {
      dispatch({
        type: DELETE_FEEDBACK,
        payload: feedbackId,
      });
    })
    .catch((err) => console.log(err));
};

export const filterFeedback = (feedbackSortType) => (dispatch) => {
  dispatch({
    type: FILTER_FEEDBACK,
    payload: { feedbackSortType },
  });
};

export const clearFeedback = () => (dispatch) => {
  dispatch({
    type: CLEAR_FEEDBACK,
    payload: null,
  });
};

export const setProjectsLoading = (dispatch) => {
  dispatch({
    type: SET_PROJECTS_LOADING,
    payload: null,
  });
};

export const setFeedbackLoading = (dispatch) => {
  dispatch({
    type: SET_FEEDBACK_LOADING,
    payload: null,
  });
};
