import React, { Component } from "react";
import "./Checkbox.scss";

class CheckboxComponent extends Component {
  state = { checked: false };

  componentDidMount() {
    if (this.props.checked) {
      this.setState({ checked: true });
    }
  }

  handleChange = (e) => {
    this.setState({ checked: !this.state.checked }, () => {
      this.props.handleCheck(this.state.checked);
    });
  };

  render() {
    return (
      <label className="checkbox-container">
        <input
          disabled={this.props.disabled}
          type="checkbox"
          checked={this.state.checked}
          onChange={this.handleChange}
        />
        <span
          className={
            this.props.disabled ? " input-disabled checkmark" : "checkmark"
          }
        />
      </label>
    );
  }
}

export default CheckboxComponent;
