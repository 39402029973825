import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import "./Admin.scss";
import axios from "axios";
import JSONPretty from "react-json-pretty";
import { AXIOS_BASE_URL } from "../actions/axiosBaseUrl";

let JSONPrettyMon = require("react-json-pretty/dist/1337");

class Admin extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    this.mounted = true;

    if (this.props.auth.user.email === "rishipr@umich.edu") {
      setTimeout(() => {
        axios
          .get(`${AXIOS_BASE_URL}/projects/stats`)
          .then((res) => this.setState({ data: res.data }))
          .catch((err) => this.setState({ data: { error: err.toString() } }));
      }, 300);
    } else {
      let json = {
        error: "Permission denied.",
      };

      this.setState({ data: json });

      this.props.history.push("/dashboard");
    }
  }

  componentDidUpdate() {
    if (
      this.props.auth.isLoggedIn &&
      this.props.auth.user.email !== "rishipr@umich.edu"
    ) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    let { data } = this.state;

    return (
      <div className="page">
        <div className="dashboard-header mb-16">Admin</div>
        {data ? (
          <>
            <Link to="/dashboard" className="lozenge lozenge-blue ml-0">
              ← BACK HOME
            </Link>
            <JSONPretty
              id="json-pretty"
              data={data}
              className="admin-stats mt-16"
              theme={JSONPrettyMon}
            ></JSONPretty>
          </>
        ) : (
          <div className="input-button-loader loader-dark mt-16"></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(Admin));
