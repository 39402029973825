import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getProjects,
  getFeedback,
  clearFeedback,
} from "../actions/projectActions";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { Link, withRouter } from "react-router-dom";

import ProjectModal from "./ProjectModal";
import Dropdown from "./Dropdown";
import FeedbackItem from "./FeedbackItem";
import { deleteProject, filterFeedback } from "../actions/projectActions";

import { ReactComponent as DeleteIcon } from "../img/delete.svg";
import { ReactComponent as EditIcon } from "../img/edit.svg";
import { ReactComponent as ViewIcon } from "../img/view.svg";
import { ReactComponent as CopyIcon } from "../img/copy.svg";
import { ReactComponent as ExportIcon } from "../img/export.svg";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import { isMobile } from "./Home";
import { CSVLink } from "react-csv";

import "./Project.scss";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

class Project extends Component {
  state = {
    project: null,
    projectName: "",
    projectId: null,
    feedback: [],
    editModal: false,
    error: null,
    sortedType: false,
    sortedEmail: false,
    sortedDate: true,
    viewEdit: false,
    isOwner: false,
    csvData: [],
  };

  async componentDidMount() {
    let {
      match: { params },
    } = this.props;

    this.mounted = true;
    let { projectId } = params;
    this.setState({ projectId });

    this.props.getFeedback(projectId, this.handleSetData, this.handleError);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.feedbackList !== prevProps.feedbackList) {
      // Set up export to CSV
      let csvData = [
        ["Project Name:", this.state.projectName],
        [
          "Project Owner:",
          `${this.state.project.creator.name}, ${this.state.project.creator.email}`,
        ],
        [
          "Project Collaborators:",
          this.state.project.projectCollaborators.toString(),
        ],
        ["Feedback Count:", this.state.feedback.length],
        [""],
        ["Feedback List"],
        ["Date", "Subproject", "Path", "Email", "Type", "Message"],
      ];

      let { feedbackList } = this.props;

      await this.asyncForEach(feedbackList, async (f) => {
        let item = [
          f.date,
          f.subProject,
          f.feedbackSrc,
          f.feedbackEmail,
          f.feedbackType,
          f.feedbackMsg,
        ];
        csvData.push(item);
      });

      this.setState({ csvData });
    }
  }

  componentWillUnmount() {
    this.props.clearFeedback();
    this.mounted = false;
  }

  handleSetData = (feedback, project) => {
    this.setState({ feedback, projectName: project.projectName, project });

    if (project && project.creator.email === this.props.auth.user.email) {
      this.setState({ isOwner: true });
    }
  };

  handleError = (error) => {
    this.setState({ error: error });
  };

  handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let textArea = document.createElement("textarea");
    textArea.value = this.state.projectId;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand("copy");
    textArea.remove();
  };

  handleEdit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ editModal: !this.state.editModal });
  };

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  render() {
    let {
      projects,
      feedbackList,
      feedbackSortDescending,
      feedbackSortType,
    } = this.props;
    let {
      projectName,
      projectId,
      error,
      project,
      editModal,
      csvData,
    } = this.state;

    // Error or loading state
    if (error || projects.feedbackLoading) {
      return (
        <div className="page">
          <Link to="/dashboard" className="lozenge lozenge-blue ml-0">
            ← BACK HOME
          </Link>
          <div className="mt-16">
            {error ? (
              <span className="lozenge lozenge-red ml-0">{error}</span>
            ) : (
              <div className="mt-16 input-button-loader loader-dark"></div>
            )}
          </div>
        </div>
      );
    }
    // Regular state
    else
      return (
        <div className="page">
          <TransitionGroup component={null}>
            {editModal && (
              <CSSTransition
                in={editModal}
                classNames="dialog"
                timeout={{
                  enter: 300,
                  exit: 300,
                }}
              >
                <ProjectModal
                  project={this.state.project}
                  edit
                  isOwner={this.state.isOwner}
                  handleModal={this.handleEdit}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
          <Link to="/dashboard" className="lozenge lozenge-blue ml-0">
            ← BACK HOME
          </Link>
          <div className="project-page-first-row mt-16 mb-8">
            <div className="dashboard-header">{projectName}</div>
            <Dropdown
              projectCard
              items={
                <>
                  <li onClick={this.handleCopy}>
                    <CopyIcon className="dropdown-icon" /> Copy Project ID
                  </li>
                  {project &&
                  project.creator.email === this.props.auth.user.email ? (
                    <>
                      <li
                        onClick={this.props.deleteProject.bind(
                          this,
                          projectId,
                          this.props.history
                        )}
                      >
                        <DeleteIcon className="dropdown-icon" /> Delete Project
                      </li>
                      <li onClick={this.handleEdit}>
                        <EditIcon className="dropdown-icon" /> Edit Project
                      </li>
                    </>
                  ) : (
                    <>
                      <li onClick={this.handleEdit}>
                        <ViewIcon className="dropdown-icon" /> View Project Info
                      </li>
                    </>
                  )}
                  {!isMobile && csvData.length > 0 && (
                    <CSVLink
                      filename={`${this.state.projectName
                        .toLowerCase()
                        .trim()
                        .replace(
                          " ",
                          "-"
                        )}-${new Date()
                        .toISOString()
                        .substring(0, 19)
                        .replace("T", " ")
                        .replace(" ", "-")}.csv`}
                      data={this.state.csvData}
                    >
                      <li>
                        <ExportIcon className="dropdown-icon" /> Export to CSV
                      </li>
                    </CSVLink>
                  )}
                </>
              }
            />
          </div>
          {project && project.creator.email === this.props.auth.user.email ? (
            <div className="mb-16">
              <span className="lozenge lozenge-black ml-0">Creator</span>
            </div>
          ) : (
            <div className="mb-16">
              <span className="lozenge lozenge-gray ml-0">Collaborator</span>
            </div>
          )}
          {/* Show "No feedback" or "Table" */}
          {feedbackList.length === 0 ? (
            <span className="ml-0 lozenge lozenge-gray">No feedback</span>
          ) : (
            // Table
            <>
              <span className="lozenge ml-0 lozenge-blue">
                {feedbackList.length + " feedback collected"}
              </span>
              <div className="filter-toggles-title">Sort</div>
              <div className="filter-toggles">
                <div
                  className={
                    feedbackSortType === "date"
                      ? "lozenge lozenge-blue"
                      : "lozenge lozenge-gray"
                  }
                  onClick={() => {
                    this.props.filterFeedback("date");
                  }}
                >
                  Date{" "}
                  {feedbackSortType === "date"
                    ? feedbackSortDescending
                      ? "↓"
                      : "↑"
                    : null}
                </div>

                <div
                  className={
                    feedbackSortType === "subProject"
                      ? "lozenge lozenge-blue"
                      : "lozenge lozenge-gray"
                  }
                  onClick={() => {
                    this.props.filterFeedback("subProject");
                  }}
                >
                  Subproject{" "}
                  {feedbackSortType === "subProject"
                    ? feedbackSortDescending
                      ? "↓"
                      : "↑"
                    : null}
                </div>

                <div
                  className={
                    feedbackSortType === "srcPath"
                      ? "lozenge lozenge-blue"
                      : "lozenge lozenge-gray"
                  }
                  onClick={() => {
                    this.props.filterFeedback("srcPath");
                  }}
                >
                  Path{" "}
                  {feedbackSortType === "srcPath"
                    ? feedbackSortDescending
                      ? "↓"
                      : "↑"
                    : null}
                </div>

                <div
                  className={
                    feedbackSortType === "email"
                      ? "lozenge lozenge-blue"
                      : "lozenge lozenge-gray"
                  }
                  onClick={() => {
                    this.props.filterFeedback("email");
                  }}
                >
                  Email{" "}
                  {feedbackSortType === "email"
                    ? feedbackSortDescending
                      ? "↓"
                      : "↑"
                    : null}
                </div>
                <div
                  className={
                    feedbackSortType === "type"
                      ? "lozenge lozenge-blue"
                      : "lozenge lozenge-gray"
                  }
                  onClick={() => {
                    this.props.filterFeedback("type");
                  }}
                >
                  Type{" "}
                  {feedbackSortType === "type"
                    ? feedbackSortDescending
                      ? "↓"
                      : "↑"
                    : null}
                </div>
                <div
                  className={
                    feedbackSortType === "message"
                      ? "lozenge lozenge-blue"
                      : "lozenge lozenge-gray"
                  }
                  onClick={() => {
                    this.props.filterFeedback("message");
                  }}
                >
                  Message{" "}
                  {feedbackSortType === "message"
                    ? feedbackSortDescending
                      ? "↓"
                      : "↑"
                    : null}
                </div>
              </div>
              <Table className="feedback-table">
                <Thead>
                  <Tr>
                    <Th
                      className={
                        feedbackSortType === "date"
                          ? "sortable active-sorted"
                          : "sortable"
                      }
                      onClick={() => {
                        this.props.filterFeedback("date");
                      }}
                    >
                      Date{" "}
                      {feedbackSortType === "date" ? (
                        <span className="table-filter">
                          {feedbackSortDescending ? "↓" : "↑"}
                        </span>
                      ) : null}
                    </Th>
                    <Th
                      className={
                        feedbackSortType === "subProject"
                          ? "sortable active-sorted"
                          : "sortable"
                      }
                      onClick={() => {
                        this.props.filterFeedback("subProject");
                      }}
                    >
                      Subproject{" "}
                      {feedbackSortType === "subProject" ? (
                        <span className="table-filter">
                          {feedbackSortDescending ? "↓" : "↑"}
                        </span>
                      ) : null}
                    </Th>
                    <Th
                      className={
                        feedbackSortType === "srcPath"
                          ? "sortable active-sorted"
                          : "sortable"
                      }
                      onClick={() => {
                        this.props.filterFeedback("srcPath");
                      }}
                    >
                      Path{" "}
                      {feedbackSortType === "srcPath" ? (
                        <span className="table-filter">
                          {feedbackSortDescending ? "↓" : "↑"}
                        </span>
                      ) : null}
                    </Th>
                    <Th
                      className={
                        feedbackSortType === "email"
                          ? "sortable active-sorted"
                          : "sortable"
                      }
                      onClick={() => {
                        this.props.filterFeedback("email");
                      }}
                    >
                      Email{" "}
                      {feedbackSortType === "email" ? (
                        <span className="table-filter">
                          {feedbackSortDescending ? "↓" : "↑"}
                        </span>
                      ) : null}
                    </Th>
                    <Th
                      className={
                        feedbackSortType === "type"
                          ? "sortable active-sorted"
                          : "sortable"
                      }
                      onClick={() => {
                        this.props.filterFeedback("type");
                      }}
                    >
                      Type{" "}
                      {feedbackSortType === "type" ? (
                        <span className="table-filter">
                          {feedbackSortDescending ? "↓" : "↑"}
                        </span>
                      ) : null}
                    </Th>
                    <Th
                      className={
                        feedbackSortType === "message"
                          ? "sortable active-sorted"
                          : "sortable"
                      }
                      onClick={() => {
                        this.props.filterFeedback("message");
                      }}
                    >
                      Message{" "}
                      {feedbackSortType === "message" ? (
                        <span className="table-filter">
                          {feedbackSortDescending ? "↓" : "↑"}
                        </span>
                      ) : null}
                    </Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {feedbackList.length > 0 &&
                    feedbackList.map((f, i) => (
                      <FeedbackItem
                        feedback={feedbackList}
                        projectId={this.state.projectId}
                        key={Math.random()}
                        i={i}
                        f={f}
                      />
                    ))}
                </Tbody>
              </Table>
            </>
          )}
        </div>
      );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  projects: state.projects,
  projectsList: state.projects.projectsList,
  feedbackList: state.projects.feedbackList,
  feedbackSortType: state.projects.feedbackSortType,
  feedbackSortDescending: state.projects.feedbackSortDescending,
});

export default connect(mapStateToProps, {
  getProjects,
  getFeedback,
  clearFeedback,
  deleteProject,
  filterFeedback,
})(withRouter(Project));
