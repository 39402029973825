import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import "./Home.scss";

import feedbackDb from "../img/feedback-db.png";

export const isMobile =
  typeof navigator !== "undefined" &&
  (/Android|Mobi|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|ZuneWP7|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) ||
    navigator.userAgent.indexOf("Mobile") > 0);

const installStr = "npm install feeder-react-feedback";

class Home extends Component {
  state = {
    copyText: installStr,
  };

  componentDidMount() {
    if (this.props.auth.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
    this.mounted = true;
  }

  componentDidUpdate() {
    if (this.props.auth.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleCopy = (e) => {
    let textArea = document.createElement("textarea");
    textArea.value = installStr;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand("copy");
    this.setState({ copyText: "Copied!" });

    setTimeout(
      () => this.mounted && this.setState({ copyText: installStr }),
      1000
    );
    textArea.remove();
  };

  render() {
    let { copyText } = this.state;
    return (
      <>
        <div className="home-page">
          <div className="home-header">
            The easiest and fastest way to collect feedback across your React
            apps.
          </div>
          <div
            onMouseOver={() =>
              !isMobile && copyText !== "Copied!"
                ? this.setState({ copyText: "Copy to clipboard" })
                : null
            }
            onMouseOut={() =>
              !isMobile && copyText !== "Copied!"
                ? this.setState({ copyText: installStr })
                : null
            }
            onClick={!isMobile ? this.handleCopy : null}
            className="home-code"
          >
            {copyText}
          </div>
          <a
            className="home-repo-link"
            rel="noopener noreferrer"
            href="https://github.com/rishipr/feeder-react-feedback"
            target="_blank"
          >
            Github Repo & Documentation
          </a>
          <div className="github-badges">
            <a
              rel="noopener noreferrer"
              href="https://www.npmjs.com/package/feeder-react-feedback"
              target="_blank"
            >
              <img
                src="https://img.shields.io/npm/v/feeder-react-feedback.svg"
                alt=""
              />
            </a>
            {/* <a
              rel="noopener noreferrer"
              href="https://www.npmjs.com/package/feeder-react-feedback"
              target="_blank"
            >
              <img
                src="https://img.shields.io/github/stars/rishipr/feeder-react-feedback.svg"
                alt=""
              />
            </a> */}
            <a
              rel="noopener noreferrer"
              href="https://www.npmjs.com/package/feeder-react-feedback"
              target="_blank"
            >
              <img
                src="https://img.shields.io/npm/dm/feeder-react-feedback.svg"
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="home-page home-page-2">
          <div className="home-page-img">
            <img src={feedbackDb} alt="" />
          </div>
          <div className="dashboard-header mb-16">
            Keep it simple, keep it fast.
          </div>
          <div className="dashboard-description">
            Feeder is a turnkey solution for developers to start collecting
            feedback across their React apps.{" "}
            <a
              rel="noopener noreferrer"
              href="https://github.com/rishipr/feeder-react-feedback"
              target="_blank"
            >
              Get up and running
            </a>{" "}
            in less than 5 minutes.
          </div>
          <Link className="home-repo-link mt-16" to="/register">
            Create an Account
          </Link>
          <a
            href="https://www.producthunt.com/posts/feeder-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-feeder-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=217281&theme=light"
              alt="Feeder - The easiest way to collect feedback across your React apps | Product Hunt Embed"
              style={{ width: "250px", height: "54px", marginTop: "40px" }}
              width="250px"
              height="54px"
            />
          </a>
          <div className="author">
            Created by{" "}
            <a
              href="https://github.com/rishipr"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rishi Prasad
            </a>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(Home));
