import React, { Component } from "react";
import { Tr, Td } from "react-super-responsive-table";
import { connect } from "react-redux";
import { deleteFeedback } from "../actions/projectActions";
const MAX_CHARS = 240;

const COLORS = ["blue", "red", "yellow", "gray", "black"];

class FeedbackItem extends Component {
  state = {
    minimized: true,
    typeColors: null,
  };

  async componentDidMount() {
    let tempArr = [];
    let feedbackTypes = Array.from(
      new Set(this.props.feedback.map((f) => f.feedbackType))
    );

    await this.asyncForEach(feedbackTypes, async (f, i) => {
      let obj = {
        type: f,
        color: i % 5,
      };

      tempArr.push(obj);
    });

    this.setState({ typeColors: tempArr });
  }

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.deleteFeedback(this.props.f._id, this.props.projectId);
  };

  render() {
    let { f, i } = this.props;
    let { minimized } = this.state;

    return (
      <Tr
        onClick={() => this.setState({ minimized: !this.state.minimized })}
        className={i % 2 === 0 ? `white-row` : `gray-row`}
        key={Math.random()}
      >
        <Td key={Math.random()}>{f.date.substring(0, 10)}</Td>
        <Td key={Math.random()}>
          <span
            className={
              f.subProject
                ? "feedback-subproject-label"
                : "lozenge lozenge-gray m-0"
            }
          >
            {f.subProject ? f.subProject : "N/A"}
          </span>
        </Td>
        <Td key={Math.random()}>
          <span className={f.feedbackSrc ? null : "lozenge lozenge-gray m-0"}>
            {f.feedbackSrc ? f.feedbackSrc : "N/A"}
          </span>
        </Td>
        <Td key={Math.random()}>
          <span
            className={
              !f.feedbackEmail
                ? "lozenge lozenge-gray m-0"
                : f.feedbackEmail === this.props.auth.user.email
                ? "lozenge lozenge-blue m-0"
                : null
            }
          >
            {f.feedbackEmail === this.props.auth.user.email
              ? "You"
              : f.feedbackEmail
              ? f.feedbackEmail
              : "N/A"}
          </span>
        </Td>
        <Td key={Math.random()}>
          <span
            className={
              this.state.typeColors
                ? `m-0 lozenge lozenge-${
                    COLORS[
                      this.state.typeColors
                        .filter(({ type }) => type === f.feedbackType)
                        .map(({ color }) => color)
                    ]
                  }`
                : "m-0 lozenge lozenge-gray"
            }
          >
            {f.feedbackType ? f.feedbackType : "N/A"}
          </span>
        </Td>
        <Td key={Math.random()}>
          <span className={!f.feedbackMsg ? "m-0 lozenge lozenge-gray" : null}>
            {!f.feedbackMsg
              ? "N/A"
              : minimized && f.feedbackMsg.length >= MAX_CHARS
              ? f.feedbackMsg.substring(0, MAX_CHARS) + "... "
              : f.feedbackMsg}
          </span>
        </Td>
        <Td>
          <span
            onClick={this.handleDelete}
            className="lozenge lozenge-red ml-0"
          >
            Delete
          </span>
        </Td>
      </Tr>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteFeedback })(FeedbackItem);
