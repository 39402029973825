import React, { Component } from "react";
import { logoutUser } from "../actions/authActions";
import { getProjects } from "../actions/projectActions";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Components
import "./Dashboard.scss";
import ProjectCardList from "./ProjectCardList";
import ProjectModal from "./ProjectModal";

import { TransitionGroup, CSSTransition } from "react-transition-group";

class Dashboard extends Component {
  state = {
    tab: "personal",
    modal: false,
  };

  componentDidMount() {
    this.props.getProjects();
  }

  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    let { tab, modal } = this.state;
    let { projects } = this.props;

    return (
      <div className="page">
        <TransitionGroup component={null}>
          {modal && (
            <CSSTransition
              in={modal}
              classNames="dialog"
              timeout={{
                enter: 300,
                exit: 300,
              }}
            >
              <ProjectModal handleModal={this.handleModal} />
            </CSSTransition>
          )}
        </TransitionGroup>
        <div className="dashboard-header">Projects</div>
        {/* <div className="dashboard-description">
          Manage your projects and view feedback.
        </div> */}
        <button className="main-btn mt-16" onClick={this.handleModal}>
          New Project
        </button>
        <div className="dashboard-tabs">
          <div
            className={
              tab === "personal" ? "dashboard-tab tab-active" : "dashboard-tab"
            }
            onClick={() => this.setState({ tab: "personal" })}
          >
            Created by You
          </div>
          <div
            className={
              tab === "shared" ? "dashboard-tab tab-active" : "dashboard-tab"
            }
            onClick={() => this.setState({ tab: "shared" })}
          >
            Shared with You
          </div>
        </div>
        {projects.projectsLoading ? (
          <div className="input-button-loader loader-dark"></div>
        ) : tab === "personal" &&
          projects.projectsList.filter(
            (project) => project.creator.email === this.props.auth.user.email
          ).length === 0 ? (
          <span className="lozenge lozenge-gray ml-0">
            No Personal Projects
          </span>
        ) : tab !== "personal" &&
          projects.projectsList.filter(
            (project) => project.creator.email !== this.props.auth.user.email
          ).length === 0 ? (
          <span className="lozenge lozenge-gray ml-0">No Shared Projects</span>
        ) : (
          <ProjectCardList tab={tab} projects={projects.projectsList} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  projects: state.projects,
});

export default connect(mapStateToProps, { logoutUser, getProjects })(
  withRouter(Dashboard)
);
