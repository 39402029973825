import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createProject, updateProject } from "../actions/projectActions";
import * as validator from "email-validator";
import TextareaAutosize from "react-textarea-autosize";

import CheckboxComponent from "./CheckboxComponent";

import "./ProjectModal.scss";

class ProjectModal extends Component {
  state = {
    projectName: "",
    projectDescription: "",
    projectCollaborators: "",
    loading: false,
    error: null,
    lengthError: null,
    invalidEmails: [],
    notifications: false,
  };

  async componentDidMount() {
    this.mounted = true;
    if (this.props.edit) {
      let collaborators = "";
      await this.asyncForEach(
        this.props.project.projectCollaborators,
        async (c, i) => {
          collaborators += `${c}${
            i !== this.props.project.projectCollaborators.length - 1 ? ", " : ""
          }`;
        }
      );

      this.setState({
        projectName: this.props.project.projectName,
        projectDescription: this.props.project.projectDescription,
        projectCollaborators: collaborators,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (
        this.state.projectName.length > 24 ||
        this.state.projectDescription.length > 80
      ) {
        this.setState({ lengthError: "Please fix the errors above." });
      } else {
        this.setState({ lengthError: null });
      }
    });
  };

  handleNotifications = (value) => {
    this.setState({ notifications: value });
  };

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ error: null, loading: true, invalidEmails: [] });
    let {
      projectName,
      projectDescription,
      projectCollaborators,
      notifications,
    } = this.state;
    projectName = projectName.trim();

    let projectCollaboratorsArr = projectCollaborators.split(",").map((c) => {
      return c.toLowerCase().trim();
    });

    if (projectCollaborators.trim().length > 0) {
      await this.asyncForEach(projectCollaboratorsArr, async (c) => {
        if (!validator.validate(c)) {
          this.setState((prevState) => ({
            invalidEmails: [...prevState.invalidEmails, c],
          }));
        }
      });
    }

    if (this.state.invalidEmails.length > 0) {
      this.setState({ loading: false });

      return;
    }

    let project = {
      projectName,
      projectDescription,
      projectCollaborators: projectCollaboratorsArr,
      notifications,
    };

    if (this.props.edit) {
      project.projectId = this.props.project._id;

      this.props.updateProject(
        project,
        this.props.handleModal,
        this.handleError,
        this.handleLoading
      );
    } else {
      this.props.createProject(
        project,
        this.props.handleModal,
        this.handleError,
        this.handleLoading
      );
    }
  };

  handleLoading = () => {
    this.mounted && this.setState({ loading: false });
  };

  handleError = (error) => {
    this.mounted && this.setState({ error: error });
  };

  render() {
    let { props } = this;
    let {
      error,
      lengthError,
      loading,
      projectName,
      projectDescription,
      invalidEmails,
    } = this.state;

    return (
      <div className="create-modal-wrapper">
        <div className="create-modal-container">
          <div className="create-modal-first-row">
            <div className="form-header">
              {props.edit ? (props.isOwner ? "Edit" : "View Info") : "Create"}
            </div>
            <div
              className="create-modal-close"
              onClick={this.props.handleModal}
            >
              CLOSE
            </div>
          </div>
          {props.edit && !props.isOwner && (
            <span className="project-card-owner lozenge lozenge-blue ml-0 mb-16">
              OWNER: {props.project.creator.email}
            </span>
          )}
          <form onSubmit={this.handleSubmit}>
            <div className="input-group">
              <div
                className={
                  projectName.length > 24
                    ? "input-label input-label-error"
                    : "input-label"
                }
              >
                Name * ({projectName.length}/24)
              </div>
              <input
                disabled={props.edit && !props.isOwner}
                className={
                  props.edit && !props.isOwner
                    ? "input input-disabled"
                    : "input"
                }
                onChange={this.handleChange}
                value={projectName}
                required
                id="projectName"
                name="projectName"
                type="text"
                placeholder="My Awesome Project"
              />
            </div>
            <div className="input-group">
              <div
                className={
                  projectDescription.length > 80
                    ? "input-label input-label-error"
                    : "input-label"
                }
              >
                Description ({projectDescription.length}/80)
              </div>
              <TextareaAutosize
                disabled={props.edit && !props.isOwner}
                className={
                  props.edit && !props.isOwner
                    ? "input text-area-input input-disabled"
                    : "input text-area-input "
                }
                onChange={this.handleChange}
                value={projectDescription}
                id="projectDescription"
                name="projectDescription"
                type="text"
                placeholder="This project does X, Y and Z."
              />
            </div>
            <div className="input-group">
              <div className="input-label">
                Collaborators (comma-separated emails)
              </div>
              <TextareaAutosize
                disabled={props.edit && !props.isOwner}
                className={
                  props.edit && !props.isOwner
                    ? "input text-area-input input-disabled"
                    : "input text-area-input"
                }
                onChange={this.handleChange}
                value={this.state.projectCollaborators}
                id="projectCollaborators"
                name="projectCollaborators"
                type="text"
                placeholder="john@doe.com, jane@test.com"
              />
            </div>
            <div className="input-group">
              <div className="input-label">
                Email Notifications (only for owner)
              </div>
              <CheckboxComponent
                disabled={props.edit && !props.isOwner}
                checked={
                  this.props.project ? this.props.project.notifications : false
                }
                handleCheck={this.handleNotifications}
              />
            </div>
            {error && <div className="input-error mb-8 mt-4">{error}</div>}
            {lengthError && (
              <div className="input-error mb-8 mt-4">{lengthError}</div>
            )}
            {invalidEmails.length > 0 && !lengthError && (
              <div className="input-error mb-8 mt-4">
                Invalid Emails:{" "}
                {invalidEmails.map((e, i) => {
                  return (
                    <span>
                      {e}
                      {i === invalidEmails.length - 1 ? null : ", "}
                    </span>
                  );
                })}
              </div>
            )}
            {(props.isOwner || !props.edit) && (
              <button
                disabled={this.state.lengthError}
                className="main-btn mt-8"
              >
                {loading ? (
                  <div className="input-button-loader"></div>
                ) : (
                  <span>{this.props.edit ? "Update" : "Create"}</span>
                )}
              </button>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default connect(null, { createProject, updateProject })(
  withRouter(ProjectModal)
);
