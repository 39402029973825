import React, { Component } from "react";
import { logoutUser } from "../actions/authActions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";

import Dropdown from "./Dropdown";
import { ReactComponent as ProjectIcon } from "../img/projects.svg";
import { ReactComponent as AuthIcon } from "../img/login.svg";
import { ReactComponent as RegisterIcon } from "../img/register.svg";
import { ReactComponent as WebIcon } from "../img/web.svg";

import "./Navbar.scss";

class Navbar extends Component {
  render() {
    let { isLoggedIn, user } = this.props.auth;
    let { name } = user;
    let initials;
    if (name) {
      if (name.split(" ").length > 1) {
        initials = name.split(" ")[0][0] + name.split(" ")[1][0];
      } else {
        initials = name[0];
      }
    }

    return (
      <header className="navbar">
        <div className="navbar-left">
          <NavLink
            activeClassName="nav-active"
            to="/"
            className="navbar-item logo"
          >
            FEEDER
          </NavLink>
        </div>
        <div
          className={
            isLoggedIn ? "navbar-right" : "navbar-right unauthenticated"
          }
        >
          {/* <span className="navbar-item">Item</span> */}
          {isLoggedIn ? (
            <>
              {/* <span
                onClick={() => this.props.logoutUser(this.props.history)}
                className="navbar-item navbar-profile"
              >
                {name && initials}
              </span> */}
              <Dropdown
                dropdownText={name && initials}
                items={
                  <>
                    {user.email === "rishipr@umich.edu" && (
                      <NavLink activeClassName="nav-active" to="/admin">
                        <li>
                          <RegisterIcon className="dropdown-icon" />
                          Admin
                        </li>
                      </NavLink>
                    )}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/rishipr/feeder-react-feedback"
                    >
                      <li>
                        <WebIcon className="dropdown-icon" />
                        Docs
                      </li>
                    </a>
                    <NavLink activeClassName="nav-active" to="/">
                      <li>
                        <ProjectIcon className="dropdown-icon" />
                        Projects
                      </li>
                    </NavLink>
                    <li
                      onClick={() => this.props.logoutUser(this.props.history)}
                    >
                      <AuthIcon className="dropdown-icon logout-icon" />
                      Log Out
                    </li>
                  </>
                }
              />
            </>
          ) : (
            <>
              <div className="unauthed-large">
                <a
                  className="navbar-item"
                  rel="noopener noreferrer"
                  href="https://github.com/rishipr/feeder-react-feedback"
                  target="_blank"
                >
                  Docs
                </a>
                <NavLink
                  activeClassName="nav-active"
                  to="/register"
                  className="navbar-item"
                >
                  Register
                </NavLink>
                <NavLink
                  activeClassName="nav-active"
                  to="/login"
                  className="navbar-item main-btn"
                >
                  Sign In
                </NavLink>
              </div>
              <Dropdown
                dropdownText={"Test"}
                unauthed
                items={
                  <>
                    <a
                      rel="noopener noreferrer"
                      href="https://github.com/rishipr/feeder-react-feedback"
                      target="_blank"
                    >
                      <li>
                        <WebIcon className="dropdown-icon" />
                        Docs
                      </li>
                    </a>
                    <NavLink activeClassName="nav-active" to="/register">
                      <li>
                        <RegisterIcon className="dropdown-icon" />
                        Register
                      </li>
                    </NavLink>
                    <NavLink activeClassName="nav-active" to="/login">
                      <li>
                        <AuthIcon className="dropdown-icon" /> Sign In
                      </li>
                    </NavLink>
                  </>
                }
              />
            </>
          )}
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Navbar));
