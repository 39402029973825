import React from "react";
import { logoutUser } from "../actions/authActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Dropdown.scss";
import { ReactComponent as Menu } from "../img/menu-dots.svg";

class Dropdown extends React.Component {
  constructor() {
    super();

    this.state = {
      displayMenu: false,
    };
  }

  showDropdownMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.hideDropdownMenu);
  }

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  };

  render() {
    let { props } = this;
    let { user, isLoggedIn } = props.auth;

    return (
      <div className="dropdown">
        {props.unauthed ? (
          <div
            className="navbar-item unauthed-menu"
            onClick={this.showDropdownMenu}
          >
            <Menu />
          </div>
        ) : props.projectCard ? (
          <div className="project-card-dots" onClick={this.showDropdownMenu}>
            <Menu />
          </div>
        ) : (
          <div
            className="navbar-item navbar-profile"
            onClick={this.showDropdownMenu}
          >
            {props.dropdownText}
          </div>
        )}

        {this.state.displayMenu && (
          <ul
            className={
              props.unauthed || props.projectCard ? "unauthed-ul" : "authed-ul"
            }
          >
            {isLoggedIn && !props.projectCard && (
              <li className="nav-user">Hey, {user.name.split(" ")[0]} 👋</li>
            )}
            {props.items}
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Dropdown));
