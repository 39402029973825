import React, { Component } from "react";

import ProjectCard from "./ProjectCard";
import "./ProjectCard.scss";
import { connect } from "react-redux";

class ProjectCardList extends Component {
  render() {
    return (
      <div className="project-cards-grid">
        {this.props.projects
          .filter((project) => {
            if (this.props.tab === "personal") {
              return project.creator.email === this.props.auth.user.email;
            } else {
              return project.creator.email !== this.props.auth.user.email;
            }
          })
          .sort((a, b) => {
            if (a.projectName < b.projectName) return -1;
            else if (a.projectName > b.projectName) return 1;
            else return 0;
          })
          .map((project, i) => (
            <ProjectCard
              key={i}
              tab={this.props.tab}
              project={project}
              projectId={project._id}
              projectName={project.projectName}
              projectDescription={project.projectDescription}
              projectURL={project.projectURL}
              feedback={project.feedback}
              feedbackGeneral={
                project.feedback.filter((f) => f.feedbackType === "general")
                  .length
              }
              feedbackBug={
                project.feedback.filter((f) => f.feedbackType === "bug").length
              }
              feedbackIdea={
                project.feedback.filter((f) => f.feedbackType === "idea").length
              }
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ProjectCardList);
