import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { setCurrentUser, logoutUser } from "./actions/authActions";

// SCSS
import "./App.scss";

// Components
import ScrollToTop from "./components/ScrollToTop";
import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import Admin from "./components/Admin";
import Navbar from "./components/Navbar";
import Project from "./components/Project";
import PrivateRoute from "./components/PrivateRoute";
import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet

// Check for token to keep user logged in
if (localStorage.feederJwtToken1337) {
  // Set auth token header auth
  const token = localStorage.feederJwtToken1337;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds

  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./";
  }
}

class App extends Component {
  state = {
    email: "",
  };
  async componentDidMount() {
    document.body.classList.remove("no-scroll");
    let {
      user: { email },
    } = await store.getState().auth;

    this.setState({ email });
  }
  render() {
    return (
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <Navbar />
            <div className="container">
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/admin" component={Admin} />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/project/:projectId"
                  component={Project}
                />
              </Switch>
            </div>
            <div className="feeder-modal">
              <Feedback
                email
                projectId="5f2cde1925fb1800045c1179"
                defaultEmailValue={this.state.email ? this.state.email : null}
                projectName="Feeder!"
                submitButtonMsg="Send Feedback 👋"
                hoverBorderColor="#005dff"
              />
            </div>
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
