import {
  CREATE_PROJECT,
  GET_PROJECTS,
  DELETE_PROJECT,
  SET_PROJECTS_LOADING,
  SET_FEEDBACK_LOADING,
  GET_FEEDBACK,
  CLEAR_FEEDBACK,
  DELETE_FEEDBACK,
  FILTER_FEEDBACK,
} from "../actions/types";

const initialState = {
  projectsList: [],
  feedbackList: [],
  projectsLoading: false,
  feedbackLoading: false,
  feedbackSortType: "date",
  feedbackSortDescending: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_PROJECT:
      let newList = state.projectsList;
      newList.unshift(action.payload);
      return {
        ...state,
        projectsList: [...newList],
      };
    case DELETE_PROJECT:
      state.projectsList = state.projectsList.filter(
        (project) => project._id !== action.payload
      );

      return {
        ...state,
        projectsList: [...state.projectsList],
      };
    case DELETE_FEEDBACK:
      state.feedbackList = state.feedbackList.filter(
        (feedback) => feedback._id !== action.payload
      );

      return {
        ...state,
        feedbackList: [...state.feedbackList],
      };
    case GET_PROJECTS:
      state.projectsList = action.payload;

      return {
        ...state,
        projectsList: [...state.projectsList],
        projectsLoading: false,
      };
    case GET_FEEDBACK:
      state.feedbackList = action.payload;

      return {
        ...state,
        feedbackList: [...state.feedbackList],
        feedbackLoading: false,
      };
    case CLEAR_FEEDBACK:
      state.feedbackList = [];
      state.feedbackSortType = "date";
      state.feedbackSortDescending = true;

      return {
        ...state,
        feedbackList: [...state.feedbackList],
      };
    case FILTER_FEEDBACK:
      let { feedbackSortType } = action.payload;

      // new type
      if (feedbackSortType !== state.feedbackSortType) {
        state.feedbackSortType = feedbackSortType;
        state.feedbackSortDescending = true;
      } else {
        state.feedbackSortDescending = !state.feedbackSortDescending;
      }

      // sort
      switch (feedbackSortType) {
        case "date":
          state.feedbackList = state.feedbackList.sort((a, b) => {
            if (a.date > b.date) {
              if (state.feedbackSortDescending) {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (state.feedbackSortDescending) {
                return 1;
              } else {
                return -1;
              }
            }
          });
          break;
        case "email":
          state.feedbackList = state.feedbackList.sort((a, b) => {
            if (!a.feedbackEmail || !b.feedbackEmail) return 0;
            if (a.feedbackEmail.toLowerCase() > b.feedbackEmail.toLowerCase()) {
              if (state.feedbackSortDescending) {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (state.feedbackSortDescending) {
                return 1;
              } else {
                return -1;
              }
            }
          });
          break;
        case "srcPath":
          state.feedbackList = state.feedbackList.sort((a, b) => {
            if (!a.feedbackSrc || !b.feedbackSrc) return 0;
            if (a.feedbackSrc.toLowerCase() > b.feedbackSrc.toLowerCase()) {
              if (state.feedbackSortDescending) {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (a.feedbackSrc.toLowerCase() === b.feedbackSrc.toLowerCase()) {
                return 0;
              }
              if (state.feedbackSortDescending) {
                return 1;
              } else {
                return -1;
              }
            }
          });
          break;
        case "type":
          state.feedbackList = state.feedbackList.sort((a, b) => {
            if (!a.feedbackType || !b.feedbackType) return 0;
            if (a.feedbackType > b.feedbackType) {
              if (state.feedbackSortDescending) {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (state.feedbackSortDescending) {
                return 1;
              } else {
                return -1;
              }
            }
          });
          break;
        case "message":
          state.feedbackList = state.feedbackList.sort((a, b) => {
            if (a.feedbackMsg.toLowerCase() > b.feedbackMsg.toLowerCase()) {
              if (state.feedbackSortDescending) {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (state.feedbackSortDescending) {
                return 1;
              } else {
                return -1;
              }
            }
          });
          break;
        case "subProject":
          state.feedbackList = state.feedbackList.sort((a, b) => {
            if (!a.subProject || !b.subProject) return 0;
            if (a.subProject.toLowerCase() > b.subProject.toLowerCase()) {
              if (state.feedbackSortDescending) {
                return 1;
              } else {
                return -1;
              }
            } else {
              if (state.feedbackSortDescending) {
                return -1;
              } else {
                return 1;
              }
            }
          });
          break;
        default:
          break;
      }

      return {
        ...state,
      };
    case SET_PROJECTS_LOADING:
      return {
        ...state,
        projectsLoading: true,
      };
    case SET_FEEDBACK_LOADING:
      return {
        ...state,
        feedbackLoading: true,
      };
    default:
      return state;
  }
}
