import React, { Component } from "react";
import { connect } from "react-redux";
import "./ProjectCard.scss";
import { Link, withRouter } from "react-router-dom";
import { deleteProject } from "../actions/projectActions";
import Dropdown from "./Dropdown";
import ProjectModal from "./ProjectModal";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { ReactComponent as DeleteIcon } from "../img/delete.svg";
import { ReactComponent as EditIcon } from "../img/edit.svg";
import { ReactComponent as ViewIcon } from "../img/view.svg";

class ProjectCard extends Component {
  state = {
    copyText: "Copy Project ID",
    editModal: false,
  };

  async componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let textArea = document.createElement("textarea");
    textArea.value = this.props.projectId;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand("copy");
    this.setState({ copyText: "Copied!" });

    setTimeout(
      () => this.mounted && this.setState({ copyText: "Copy Project ID" }),
      1000
    );
    textArea.remove();
  };

  handleCardClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.deleteProject(this.props.projectId, this.props.history);
  };

  handleEdit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ editModal: !this.state.editModal });
  };

  render() {
    let { props } = this;
    let { copyText, editModal } = this.state;

    return (
      <>
        <TransitionGroup component={null}>
          {editModal && (
            <CSSTransition
              in={editModal}
              classNames="dialog"
              timeout={{
                enter: 300,
                exit: 300,
              }}
            >
              <ProjectModal
                project={props.project}
                isOwner={
                  props.project.creator.email === this.props.auth.user.email
                }
                edit
                handleModal={this.handleEdit}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
        <Link className="project-card" to={`/project/${props.projectId}`}>
          <div className="project-first-row">
            <div className="project-name">
              {props.projectName.substring(0, 20).trim()}
              {props.projectName.trim().length > 20 ? "..." : null}
            </div>
            <Dropdown
              projectCard
              items={
                this.props.tab === "personal" ? (
                  <>
                    <li onClick={this.handleDelete}>
                      <DeleteIcon className="dropdown-icon" /> Delete Project
                    </li>
                    <li onClick={this.handleEdit}>
                      <EditIcon className="dropdown-icon" /> Edit Project
                    </li>
                  </>
                ) : (
                  <>
                    <li onClick={this.handleEdit}>
                      <ViewIcon className="dropdown-icon" /> View Project Info
                    </li>
                  </>
                )
              }
            />
          </div>
          {document.queryCommandSupported("copy") ? (
            <span className="project-id" onClick={this.handleCopy}>
              {copyText}
            </span>
          ) : (
            <span className="project-id no-copy">{props.projectId}</span>
          )}
          <div className="project-description">
            {props.projectDescription || "No description"}
          </div>
          <div className="project-lozenges">
            {props.feedback.length === 0 ? (
              <span className="lozenge lozenge-gray">no feedback received</span>
            ) : (
              <>
                {" "}
                <span className="lozenge lozenge-blue">
                  {props.feedback.length} Feedback Collected
                </span>
                {/* <span className="lozenge lozenge-red">
                  {props.feedbackBug} bug
                </span>
                <span className="lozenge lozenge-yellow">
                  {props.feedbackIdea} idea
                </span> */}
              </>
            )}
          </div>
        </Link>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteProject })(
  withRouter(ProjectCard)
);
